














































































































import Vue from "vue";
import _ from 'lodash';
import { ulid } from 'ulid';
import notificationMixin from '../../mixin/notification';
import store from '../../store/shop';
import ShopAPI from '../../common/api/api_shop';
import { TranslateServiceBuilder } from "../../services/Translate/translateServiceBuilder";

const translate = TranslateServiceBuilder.Instance()._;
const api = new ShopAPI();

export default Vue.component('Setting', {
  components: {
    QLoader: () => import('../../components/elements/QLoader.vue'),
    QButton: () => import("../../components/elements/QButton.vue"),
    QTranslate: () => import("../../components/elements/QTranslate.vue"),
  },
  mixins: [
    notificationMixin,
  ],
  data() {
    return {
      errors: {},
      mode: '',
      reset_time_hour: '',
      reset_time_minue: '',
      isLoading: false,
      isShow: false,
    };
  },
  mounted() {
    api.set_token(store.state.token);
    let time = this.reset_time.split(':');
    this.reset_time_hour = time[0].length < 2 ? '0' + time[0] : time[0];
    this.reset_time_minute = time[1].length < 2 ? '0' + time[1] : time[1];
  },
  computed: {
    setting() {
      return store.state.shopSetting;
    },
    prefix() {
      return this.$route.params.prefix;
    },
    shoppix() {
      return this.$route.params.shoppix;
    },
    langs() {
      return store.state.langs.filter((lang) => lang.is_enable);
    },
    selectedLang: {
      get() {
        const target = _.find(
          this.langs,
          (lang) => lang.locale === store.state.langKey
        );
        if (!target) {
          return "---";
        }

        return target.locale;
      },
      set(selectKey: string) {
        store.dispatch("selectLangKey", selectKey);
      },
    },
    sound() {
      return store.state.sound;
    },
    last_order_times() {
      return _.map(store.state.shopSetting.last_order_times, function (item) {
        let time_array = item.time.split(":");

        item.hour = time_array[0].length < 2 ? '0' + time_array[0] : time_array[0];
        item.minute = time_array[1].length < 2 ? '0' + time_array[1] : time_array[1];
        return item;
      });
    },
    reset_time() {
      return store.state.shopSetting.reset_time;
    },
  },
  methods: {
    handleTakeoutOn: function () {
      store.commit("setSoundTakeout", true);
      store.commit("setLatestTakeoutOrder", "9999999999");
    },
    handleTakeoutOff: function () {
      store.commit("setSoundTakeout", false);
      store.commit("setLatestTakeoutOrder", "0000000000");
    },
    handleSelfOrderOn: function () {
      store.commit("setSoundOrder", true);
      store.commit("setLatestOrder", "9999999999");
    },
    handleSelfOrderOff: function () {
      store.commit("setSoundOrder", false);
      store.commit("setLatestOrder", "0000000000");
    },
    setResetTime: function () {
      let data = {
        shop_id: this.shoppix,
        reset_time: this.reset_time_hour + ':' + this.reset_time_minute
      };

      this.callApiResetTime(data);
    },
    callApiResetTime: function (data) {
      let message = "";
      this.isLoading = true;
      api
        .shop_setting_reset_time(data)
        .then((response) => {
          if (response.data.status !== "success") {
            message =
              response.data.message ||
              translate("hall-0046", "保存に失敗しました。");
            this.showErrorNotification(message);
            this.errors = message;
            return;
          }

          message = response.data.message;
          this.showSuccessNotification(message);
          store.dispatch("setResetTime", data.reset_time)
          this.isShow = false;
          this.isLoading = false;
        })
        .catch((err) => {
          message =
            err.response.data.message ||
            translate("hall-0046", "保存に失敗しました。");
          this.showErrorNotification(message);
          this.errors = message;
          this.isLoading = false;
        });
    },
    displayReleaseTime: function (time) {
      return '（' + time + translate("hall-0226", "分後に解除") + '）';
    }
  },
});
